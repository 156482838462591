<template>
  <div class="home_pannel">
    <el-popover
      title="Notices"
      class="message_center_icon"
      placement="left"
      width="400"
      trigger="manual"
      v-model="isShowMessageCenterPannelFlag"
    >
      <el-divider></el-divider>
      <div v-if="!isExsitLatestArticleFlag">
        <p style="text-align: center">no new notice</p>
      </div>
      <div v-else>
        <el-row :gutter="20">
          <el-col :span="14">
            <el-tooltip
              class="item"
              effect="dark"
              :content="latestArticleInfo.title"
              placement="top"
            >
              <p class="message_center_article_title">
                {{ latestArticleInfo.title }}
              </p>
            </el-tooltip>
          </el-col>
          <el-col :span="10">
            <p>{{ timestampToTime(latestArticleInfo.publish_time) }}</p>
          </el-col>
        </el-row>
      </div>
      <el-row type="flex" justify="end">
        <el-col :span="3">
          <el-link
            @click="pushToArticleCenter"
            :underline="false"
            style="color: #409eff"
            >more<i class="el-icon-d-arrow-right"></i
          ></el-link>
        </el-col>
      </el-row>
      <el-badge
        style="float: right"
        :is-dot="isExsitLatestArticleFlag"
        class="item"
        slot="reference"
      >
        <el-button
          @click="
            isShowMessageCenterPannelFlag = !isShowMessageCenterPannelFlag
          "
          icon="el-icon-message-solid"
          type="primary"
          circle
        ></el-button
      ></el-badge>
    </el-popover>
    <el-row class="home_pannel_platform_introduce_pannel">
      <div
        class="home_pannel_platform_introduce_pannel_info"
        data-aos="fade-up"
        data-aos-duration="1"
      >
        <el-row
          ><el-col
            ><span class="platform_info"
              >An AI & Accurate Big Data-driven QSAR Platform</span
            ></el-col
          ></el-row
        >
        <el-row
          ><el-col
            ><span class="platform_name"
              >Lab of Molecular Deep Electron Cloud/Field-activity Relationships <br>
              (LabMolDECAR)</span
            ></el-col
          ></el-row
        >
        <el-row
          ><el-col
            ><span class="platform_info"
              >to share molecular electron cloud density, activity data, and
              trained network models like wiki</span
            ></el-col
          ></el-row
        >
      </div>
    </el-row>
    <el-row type="flex">
      <el-col :span="7" data-aos="fade-up" style="display: flex; align-items: center;margin-left: 2rem;"
        ><el-image
          style="width: 100%;"
          :src="require('@/assets/images/introduce_imag1.webp')"
          fit="fill"
        ></el-image></el-col
      ><el-col :span="15" data-aos="fade-up">
        <div class="content_pannel_right">
          <p class="title">What does electron cloud density (ECD) mean for a molecule?</p>
          <p class="content">
            (1) A molecule is like an electron-generated mechanical component;
          </p>
          <p class="content">
            (2) The ECD of a molecule is like X-ray/CT/B-ultrasound/NMR imaging of the component;
          </p>
          <p class="content">
            (3) ECD represents the global and local characteristics of a molecule, such as skeleton, shape, texture, softness, hardness, magnetism, electrical properties, elasticity, etc.
          </p>
          <p class="content">
            (4) ECD determines the physical or/and chemical properties of molecules, as well as their physiological activity.
          </p>
        </div>
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <el-row type="flex">
      <el-col :span="17" data-aos="fade-up">
        <div class="content_pannel_left">
          <p class="title">What is DECAR/DFAR?</p>
          <p class="content">
            Deep electron cloud/field-activity relationships (DECAR/DFAR) are QSARs with three essentials:
          </p>
          <p class="content">
            (1) Tens/hundreds of thousands of molecule entities with activity data as training objects;
          </p>
          <p class="content">
            (2) High-quality 3D electron cloud density (ECD) or related field data as input descriptors;
          </p>
          <p class="content">
            (3) A sufficiently flexible and powerful deep learning model to learn the big data.
          </p>
        </div>
      </el-col>
      <el-col :span="7" data-aos="fade-up" style="display: flex; align-items: center;margin-right: 2rem;"
        ><el-image
          style="width: 100%;"
          :src="require('@/assets/images/introduce_imag2.webp')"
          fit="fill"
        ></el-image
      ></el-col>
    </el-row>
    <el-divider></el-divider>
    <el-row type="flex">
      <el-col :span="7" data-aos="fade-up" style="display: flex; align-items: center;margin-left: 2rem;"
        ><el-image
          style="width: 100%;"
          :src="require('@/assets/images/introduce_imag3.webp')"
          fit="fill"
        ></el-image></el-col
      ><el-col :span="15" data-aos="fade-up">
        <div class="content_pannel_right">
          <p class="title">Why do we need DECAR/DFAR?</p>
          <p class="content">
            If our goal is a universal QSAR, the following points should be considered:
          </p>
          <p class="content">
            (1) Quantum mechanics cannot calculate empirical chemical properties, so we need machine learning and QSAR;
          </p>
          <p class="content">
            (2) As many structures as possible should be learned to infer activities for diverse structures;
          </p>
          <p class="content">
            (3) Garbage in, Garbage out. According to the Hohenberg-Kohn theorem, ECD data are the most accurate molecular descriptors;
          </p>
          <p class="content">
            (4) A powerful deep learning model is necessary to deal with the big data.
          </p>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-carousel :interval="4000" type="card" height="30vh" style="text-align: center;">
        <el-carousel-item v-for="(item, index) in imageList" :key="index">
          <el-image style="height: 100%" :src="item.url" :fit="fit"></el-image>
        </el-carousel-item>
      </el-carousel>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //最新文章信息
      latestArticleInfo: {
        un_code: "",
        title: "",
        publish_time: "",
      },
      //是否有最新文章标志
      isExsitLatestArticleFlag: false,
      //是否显示消息中心面板
      isShowMessageCenterPannelFlag: false,
      //图片填充方式
      fit: "contain",
      imageList: [
        {
          url: require("@/assets/images/图书馆1.webp"),
        },
        {
          url: require("@/assets/images/铜仁学院校门全景.webp"),
        },
        {
          url: require("@/assets/images/铜仁学院校名石.webp"),
        },
        {
          url: require("@/assets/images/图书馆2.webp"),
        },
        {
          url: require("@/assets/images/校园环境.webp"),
        },
        {
          url: require("@/assets/images/学生公寓楼.webp"),
        }
      ],
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      this.$store
        .dispatch("article/selectLatest", {})
        .then((res) => {
          var { data } = res;
          var unCode = data.info.un_code;
          //获取本地最新文章unCode对象
          var articleUnCode = this.$store.state.latestArticleUncode;
          //如果不存在该对象则进行初始化
          if (articleUnCode == undefined) {
            this.$store.commit("setLatestArticleUncode", {
              un_code: unCode,
              title: data.title,
              publish_time: data.publish_time,
              flag: 1,
            });
          } else {
            //如果不等，说明有新的文章，更新文章unCode，并更新是否是文章unCode的标志，1：表示是最新；0：表示不是最新
            if (articleUnCode.un_code != unCode) {
              this.$store.commit("setLatestArticleUncode", {
                un_code: unCode,
                title: data.title,
                publish_time: data.publish_time,
                flag: 1,
              });
            } else if (articleUnCode.flag == 0) {
              return;
            }
          }
          this.latestArticleInfo.un_code = unCode;
          this.latestArticleInfo.title = data.info.title;
          this.latestArticleInfo.publish_time = data.info.publish_time;
          this.isExsitLatestArticleFlag = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //跳转至文章中心方法
    pushToArticleCenter() {
      this.$router.push("/article");
    },
    //时间戳转换为时间
    timestampToTime(timestamp) {
      var date = new Date(parseInt(timestamp)); //时间戳为10位需*1000，时间戳为13位的话不需乘1000,需要将字符串转化为int
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
  },
};
</script>
<style scoped>
.home_pannel {
  overflow-y: hidden;
}
.home_pannel_platform_introduce_pannel {
  height: 32rem;
  background: url("../../assets/images/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.home_pannel_platform_introduce_pannel_info {
  padding-top: 12rem;
  margin-bottom: 2rem;
  width: 100%;
  height: 20rem;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
}
.message_center_icon {
  position: fixed; /*固定位置*/
  z-index: 99; /*设置优先级显示，保证不会被覆盖*/
  right: 1vw;
  top: 39vh;
}
.message_center_article_title {
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  white-space: nowrap;
}
.platform_info {
  font-size: 2rem;
  color: #ffd04b;
}
.platform_name {
  font-size: 5vh;
  color: #fff;
}
.content_pannel_right {
  margin-top: 5rem;
  margin-left: 2vw;
}
.content_pannel_left {
  margin-top: 5rem;
  margin-left: 6vw;
}
.title {
  color: #666;
  font-size: 2rem;
}
.content {
  font-size: 1.2rem;
  line-height: 4vh;
}
</style>